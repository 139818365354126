export default {
  // Adminstrator - Profile APIs

  createProfile: {
    url: "/api/v1/profile",
    method: "post",
  },
  getAllProfile: {
    url: "/api/v1/profile",
    method: "get",
  },
  getProfileById: {
    url: "/api/v1/profile/{profile_id}",
    method: "get",
  },
  updateProfile: {
    url: "/api/v1/profile/{profile_id}",
    method: "put",
  },
  deleteProfile: {
    url: "/api/v1/profile/{profile_id}",
    method: "delete",
  },
  // Leads
  addLead: {
    url: "/api/v1/lead",
    method: "post",
  },
  getAllLeads: {
    url: "/api/v1/lead",
    method: "get",
  },
  getLeadById: {
    url: "/api/v1/lead/{lead_id}",
    method: "get",
  },
  updateLead: {
    url: "/api/v1/lead/{lead_id}",
    method: "put",
  },
  deleteLead: {
    url: "/api/v1/lead/{lead_id}",
    method: "delete",
  },

  // Schedule Event APIs
  createEvent: {
    url: "/api/v1/school/event",
    method: "post",
  },
  getAllEvent: {
    url: "/api/v1/school/event",
    method: "get",
  },
  getEventById: {
    url: "/api/v1/school/event/id/{event_id}",
    method: "get",
  },
  createSchool: {
    url: "/api/v1/admin/school",
    method: "post",
  },
  getSchool: {
    url: "/api/v1/admin/school",
    method: "get",
  },
  getSchoolById: {
    url: "/api/v1/admin/school/{school_id}",
    method: "get",
  },
  updateSchoolById: {
    url: "/api/v1/admin/school/{school_id}",
    method: "put",
  },
  deleteSchool: {
    url: "/api/v1/admin/school/{school_id}",
    method: "delete",
  },

  // SCHOOL-TABLE-APIs

  createTable: {
    url: "/api/v1/school/table",
    method: "post",
  },
  getAllTable: {
    url: "/api/v1/school/table/{curriculum_grade}",
    method: "get",
  },
  getTableById: {
    url: "/api/v1/school/table/{table_id}",
    method: "get",
  },
  updateTableById: {
    url: "/api/v1/school/table/{table_id}",
    method: "put",
  },
  deleteTableById: {
    url: "/api/v1/school/table/{table_id}",
    method: "delete",
  },

  //STUDENT_APIs

  createStudent: {
    url: "/api/v1/school/student",
    method: "post",
  },
  getAllStudent: {
    url: "/api/v1/school/student/{curriculum_grade}",
    method: "get",
  },
  getStudentById: {
    url: "/api/v1/school/student/{student_id}",
    method: "get",
  },
  updateStudentById: {
    url: "/api/v1/school/student/{student_id}",
    method: "put",
  },
  deleteStudentById: {
    url: "/api/v1/school/student/{student_id}",
    method: "delete",
  },

  // SECTION APIs
  createSection: {
    url: "/api/v1/school/section",
    method: "post",
  },
  getAllSection: {
    url: "/api/v1/school/section/",
    method: "get",
  },
  deleteSectionById: {
    url: "/api/v1/school/section/{section}",
    method: "delete",
  },

  // Get all student

  getAllStudentResult: {
    url: "/api/v1/school/student/result/{curriculum_grade}",
    method: "get",
  },

  // GALLERY MANAGEMENT
  createGalleryPhoto: {
    url: "/api/v1/gallery/photo",
    method: "post",
  },
  getGalleryPhotos: {
    url: "/api/v1/gallery/photo",
    method: "get",
  },
  deleteGalleryPhoto: {
    url: "/api/v1/gallery/photo/{photo_id}",
    method: "delete",
  },
  //
  createGalleryVideo: {
    url: "/api/v1/gallery/video",
    method: "post",
  },
  getGalleryVideos: {
    url: "/api/v1/gallery/video",
    method: "get",
  },
  deleteGalleryVideo: {
    url: "/api/v1/gallery/video/{video_id}",
    method: "delete",
  },

  // PHASE-2 => V2 (version)

  // Add Flow API's
  addGenericInfo: {
    url: "/api/v2/school",
    method: "post",
  },
  addClassInfo: {
    url: "/api/v2/class",
    method: "post",
  },
  addSubModules: {
    url: "/api/v2/module/sub",
    method: "post",
  },
  addSchoolAdminInfo: {
    url: "/api/v2/school/admin",
    method: "post",
  },
  addSchoolCoordinator: {
    url: "/api/v2/school/school_coordinator",
    method: "post",
  },
  addInvoiceForm: {
    url: "/api/v2/invoice/",
    method: "post",
  },
  addStudent: {
    url: "/api/v2/student/",
    method: "post",
  },
  addTableData: {
    url: "/api/v2/table/",
    method: "post",
  },
  addDevice: {
    url: "/api/v2/device/",
    method: "post"
  },

  // To get data by Id
  getClassInfo: {
    url: "/api/v2/class/school/{school_id}",
    method: "get",
  },
  getAdminSchoolById: {
    url: "/api/v2/school/{school_id}",
    method: "get",
  },
  getSchoolAdmins: {
    url: "/api/v2/school/admin/{school_id}",
    method: "get",
  },
  getSchoolCoordinators: {
    url: "/api/v2/school/school_coordinator/{school_id}",
    method: "get",
  },
  getProfileDetailsById: {
    url: "/api/v2/school/school_coordinator/detail/{profile_id}",
    method: "get",
  },
  getAllTableList: {
    url: "/api/v2/table/{school_id}",
    method: "get",
  },
  getAllSchoolEvent: {
    url: "/api/v2/event/list/{school_id}",
    method: "get",
  },
  getSchoolEventById: {
    url: "/api/v2/event/{event_id}",
    method: "get",
  },

  createSchoolEvent: {
    url: "/api/v2/event/",
    method: "post",
  },

  updateSchoolEventById: {
    url: "/api/v2/event/{event_id}",
    method: "put",
  },

  // To fetch data list
  getAllSchoolListForCoordinator: {
    url: "/api/v2/school/school_coordinator/school_details",
    method: "get",
  },
  getAllModules: {
    url: "/api/v2/module/?school_id={school_id}&class_id={class_id}",
    method: "get",
  },
  getAllSchoolList: {
    url: "/api/v2/school",
    method: "get",
  },
  getAllSchoolListSuperAdmin: {
    url: "/api/v2/school/lists",
    method: "get",
  },
  getSubModules: {
    url: "/api/v2/module/sub",
    method: "get",
  },
  getInvoiceForm: {
    url: "/api/v2/invoice/",
    method: "get",
  },
  getManageAccess: {
    url: "/api/v2/school/manageaccess/{school_id}",
    method: "get",
  },
  getDevices: {
    url: "/api/v2/device/",
    method: "get",
  },

  updateManageAccess: {
    url: "/api/v2/school/manageaccess/{school_id}",
    method: "put",
  },
  getFinancialsList: {
    url: "/api/v2/invoice/financials/{school_id}",
    method: "get",
  },
  getClassDetailsById: {
    url: "/api/v2/class/{class_id}",
    method: "get",
  },
  // for school_coordinator flow
  getAllStudents: {
    url: "/api/v2/student/{school_id}",
    method: "get",
  },

  // Edit Flow API's
  updateGenericInfoById: {
    url: "/api/v2/school/{school_id}",
    method: "put",
  },
  updateSchoolAdminById: {
    url: "/api/v2/school/admin/{profile_id}",
    method: "put",
  },
  updateClassInfoById: {
    url: "/api/v2/class/{class_id}",
    method: "put",
  },
  activateSchoolOnboarding: {
    url: "/api/v2/school/activate/{school_id}",
    method: "put",
  },
  updateSubModuleById: {
    url: "/api/v2/module/sub/{sub_module_id}",
    method: "put",
  },
  updateInvoiceForm: {
    url: "/api/v2/invoice/{invoice_id}",
    method: "put",
  },
  orderRazrorPayInvoice: {
    url: "/api/v1/razorpay/order",
    method: "post",
  },
  editTableById: {
    url: "/api/v2/table/{table_id}",
    method: "put",
  },
  editStudentById: {
    url: "/api/v2/student/{profile_id}",
    method: "put",
  },
  updateDeviceById: {
    url: "/api/v2/device/{device_id}",
    method: "put",
  },
  // Delete Flow API's
  deleteSchoolAdminById: {
    url: "/api/v2/school/admin/{school_id}/{profile_id}",
    method: "delete",
  },
  deleteCoordinatorById: {
    url: "/api/v2/school/{school_id}/{profile_id}",
    method: "delete",
  },
  deleteInvoiceForm: {
    url: "/api/v2/invoice/{invoice_id}",
    method: "delete",
  },
  deleteClassInfoById: {
    url: "/api/v2/class/{class_id}",
    method: "delete",
  },
  deleteTableById: {
    url: "/api/v2/table/class/{table_id}",
    method: "delete",
  },
  deleteDevice: {
    url: "/api/v2/device/{device_id}",
    method: "delete",
  },
};
