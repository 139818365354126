import { Component } from "react";
import { toast, ToastContainer } from "react-toastify";

export class ManageAccess extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("ManageAccess");

    this.state = {
      tableData: [],
      data: [],
    };
    this.role = localStorage.getItem("currentAdminRole");
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList()
    );
  }

  componentDidMount() {
    this.fetchManageAccessData();
  }

  componentDidUpdate() {
    let accord = document.getElementsByClassName("accordion-btn");

    for (let i = 0; i < accord.length; i++) {
      accord[i].onclick = function () {
        this.classList.toggle("active");
        this.nextElementSibling.classList.toggle("show");
      };
    }
  }

  fetchManageAccessData = () => {
    let school_id =
      this.props.selectedEditMode || this.props.readMode
        ? this.props.selectedRowData.school_id
        : localStorage.getItem("school_id");
    this.props.api
      .apiGetManageAccessData({
        school_id: school_id,
      })
      .then((res) => {
        this.setState({
          tableData: res.data.classes,
        });
      })
      .catch((error) => {
        console.log({ error: error });
      });
  };

  handleModuleCheckboxChange = (event, classIndex, moduleIndex) => {
    const tableDataCopy = [...this.state.tableData];
    const moduleCopy = { ...tableDataCopy[classIndex].modules[moduleIndex] };
    const isChecked = event.target.checked;

    // Update the copy of the module with the new value
    moduleCopy.is_eligible = isChecked;

    // If the module is changed, change submodules as well
    moduleCopy.sub_modules.forEach((submodule) => {
      submodule.is_eligible = isChecked;
      submodule.sessions.forEach((session) => {
        session.is_eligible = isChecked;
      });
    });

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex] = moduleCopy;

    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });
  };

  handleSubModuleCheckboxChange = (
    event,
    classIndex,
    moduleIndex,
    submoduleIndex
  ) => {
    const tableDataCopy = [...this.state.tableData];
    const isChecked = event.target.checked;
    const submoduleCopy = {
      ...tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
        submoduleIndex
      ],
    };

    // Update the copy of the submodule with the new value
    submoduleCopy.is_eligible = isChecked;

    // If the submodule is changed, change all sessions as well
    submoduleCopy.sessions.forEach((session) => {
      session.is_eligible = isChecked;
    });

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex].sub_modules[submoduleIndex] =
      submoduleCopy;

    if (isChecked) {
      tableDataCopy[classIndex].modules[moduleIndex].is_eligible = true;
    }
    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });
  };

  handleSessionCheckboxChange = (
    event,
    classIndex,
    moduleIndex,
    submoduleIndex,
    sessionIndex
  ) => {
    const tableDataCopy = [...this.state.tableData];
    const moduleCopy = { ...tableDataCopy[classIndex].modules[moduleIndex] };
    const submoduleCopy = { ...moduleCopy.sub_modules[submoduleIndex] };
    const sessionCopy = { ...submoduleCopy.sessions[sessionIndex] };
    const isChecked = event.target.checked;

    // Update the copy of the session with the new value
    sessionCopy.is_eligible = isChecked;

    // Update the state with the modified copy
    tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
      submoduleIndex
    ].sessions[sessionIndex] = sessionCopy;

    if (isChecked) {
      tableDataCopy[classIndex].modules[moduleIndex].is_eligible = true;
      tableDataCopy[classIndex].modules[moduleIndex].sub_modules[
        submoduleIndex
      ].is_eligible = true;
    }
    // Set the updated state
    this.setState({
      tableData: tableDataCopy,
    });
  };

  handleSubmit = (e) => {
    let school_id =
      this.props.selectedEditMode || this.props.readMode
        ? this.props.selectedRowData.school_id
        : localStorage.getItem("school_id");

    let payloadData = this.state.tableData;

    this.props.api
      .apiUpdateManageAccessData(
        {
          data: {
            classes: payloadData.map(({ curriculum, ...rest }) => rest),
          },
        },
        {
          school_id: school_id,
        }
      )
      .then((res) => {
        // console.log("apiEditGenericInfo: ", res);
        toast.success("Access Data Updated Successfully", {
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
        });
        this.props.setGoSteps(3);
      })
      .catch((err) => {
        // this.setState({
        //   loader: false,
        // });
      });
  };

  render() {
    const { tableData } = this.state;

    return (
      <>
        <ToastContainer position="top-right" />
        <div className="bg-white p-3">
          {tableData.map((data, classIndex) => (
            <div key={classIndex} className="accordion nested_accordion">
              <button className="accordion-btn outer-accord">
                <span>
                  <span>Class {data.curriculum.curriculum_grade}</span>
                </span>
                <span className="arrow-border">
                  <i className="arrow up"></i>
                </span>
              </button>
              <div className="panel">
                {data.modules.map((moduleData, moduleIndex) => (
                  <div key={moduleIndex}>
                    <button className="accordion-btn inner-accord">
                      <span>
                        <input
                          className="manage_check"
                          type="checkbox"
                          disabled={this.props.readMode}
                          onChange={(e) =>
                            this.handleModuleCheckboxChange(
                              e,
                              classIndex,
                              moduleIndex
                            )
                          }
                          checked={
                            tableData[classIndex].modules[moduleIndex]
                              .is_eligible
                          }
                        />
                        <span>{moduleData.module_name}</span>
                      </span>
                      <span className="arrow-border">
                        <i className="arrow up"></i>
                      </span>
                    </button>
                    <div className="panel">
                      {moduleData.sub_modules.map(
                        (submoduleData, submoduleIndex) => (
                          <div key={submoduleIndex}>
                            <button className="accordion-btn inner-inner-accord">
                              <span>
                                <input
                                  className="manage_check"
                                  type="checkbox"
                                  disabled={this.props.readMode}
                                  onChange={(e) =>
                                    this.handleSubModuleCheckboxChange(
                                      e,
                                      classIndex,
                                      moduleIndex,
                                      submoduleIndex
                                    )
                                  }
                                  checked={
                                    tableData[classIndex].modules[moduleIndex]
                                      .sub_modules[submoduleIndex].is_eligible
                                  }
                                />
                                <span>{submoduleData.sub_module_name}</span>
                              </span>
                              <span className="arrow-border">
                                <i className="arrow up"></i>
                              </span>
                            </button>
                            <div className="panel">
                              <div className="row ml-4">
                                {submoduleData.sessions.map(
                                  (session, sessionIndex) => (
                                    <div
                                      key={sessionIndex}
                                      className="content1 col-6"
                                    >
                                      <input
                                        className="manage_check"
                                        type="checkbox"
                                        disabled={this.props.readMode}
                                        onChange={(e) =>
                                          this.handleSessionCheckboxChange(
                                            e,
                                            classIndex,
                                            moduleIndex,
                                            submoduleIndex,
                                            sessionIndex
                                          )
                                        }
                                        checked={
                                          tableData[classIndex].modules[
                                            moduleIndex
                                          ].sub_modules[submoduleIndex]
                                            .sessions[sessionIndex].is_eligible
                                        }
                                      />{" "}
                                      <span>{session.session_name}</span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {tableData.length == 0 && <div className="empty_layout"></div>}
          {/* </AppForm> */}
        </div>
        <div className="row m-auto">
            <AppForm className="flex flex-col gap-4 pt-0 pb-2">
              {!this.props.readMode && this.role != "SCHOOL_ADMIN" && (
                <div className="row no-gutters m-2 mb-4">
                  <button
                    className="btn close-btn col col-md-1 ml-auto mt-3 rounded-1 text-dark "
                    onClick={() => { this.props.selectedEditMode &&
                      this.props.schoolStatus != "PENDING" ? location.reload() : this.props.setGoSteps(1) }}
                  >
                    {this.props.selectedEditMode &&
                    this.props.schoolStatus != "PENDING"
                      ? "Cancel"
                      : "Previous"}
                  </button>
                  <button
                    type="submit"
                    className="btn save-btn col col-md-1 ml-auto mt-3 ms-2 me-2 rounded-1"
                    onClick={() => {
                      this.handleSubmit();
                    }}
                  >
                    {this.props.selectedEditMode &&
                    this.props.schoolStatus != "PENDING"
                      ? "Update"
                      : "Save & Next"}
                  </button>
                </div>
              )}
            </AppForm>
          </div>
      </>
    );
  }
}

export default connect(ManageAccess);
