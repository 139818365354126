import React, { Component } from "react";

export class AppFormData extends Component {
  render() {
    let { props } = this;
    let {
      data,
      size = 2,
      fields = {},
      submitText = "SUBMIT",
      onClose,
      disableallfields = false,
      disablefields = [],
      editdata,
      submitButton = false,
      closeButton = false,
    } = props;
    let list = Object.entries(data).chunk(size);
    let getField = (label) => fields[label] || {};
    let getColClass = (c) => fields[c]?.customcolspan || "col-lg-6";
    let formAttrs = Object.except(props, [
      "data",
      "fields",
      "size",
      "submitText",
    ]);
    return (
      <AppForm {...formAttrs}>
        <Row className="mx-0">
        {list.map((row, i) => (
          <>
            {row.map(([label, value], j) => (
              // <Col lg={12} key={j}>
              <div className={`col-6 col-sm-12 ${getColClass(label)}`}>
                <AppInput
                  name={label}
                  label={label.toTitleCase()}
                  defaultValue={value}
                  disabled={disableallfields}
                  {...(disablefields.includes(label) &&
                  Object.keys(editdata).length > 0
                    ? {
                        readOnly: true,
                      }
                    : { readOnly: false })}
                  {...getField(label)}
                ></AppInput>
              {/* </Col> */}
              </div>
            ))}
          </>
        ))}
        </Row>
        {submitButton ?
          (<div className="row no-gutters mt-2">
            <Row className="mt-4">
              <button
                type="reset"
                className="btn close-btn col col-md-1 ml-auto rounded-1 text-dark"
                data-bs-dismiss="modal"
                onClick={onClose}>
                Cancel
              </button>
              <button
                type="submit"
                className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
                {submitText}
              </button>
            </Row>
          </div>)
          : closeButton ?
          (<div className="row no-gutters mt-2">
          <Row className="mt-4 d-flex justify-end">
            <button
              type="reset"
              data-bs-dismiss="modal"
              onClick={onClose}
              className="btn save-btn col col-md-1 ml-auto ms-2 me-2 rounded-1">
              Close
            </button>
          </Row>
        </div>) 
          : (<Row className="mt-4">
            <Col
              className={Object.className({
                "text-right": true,
                hidden: disableallfields,
              })}
            >
              <button type="submit" className="btn add-btn">
                {submitText}
              </button>
            </Col>
            <Col>
              <button
                type="reset"
                className={Object.className({
                  "btn cancel-btn": true,
                  "m-auto": disableallfields,
                })}
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Cancel
              </button>
            </Col>
          </Row>)
        }
      </AppForm>
    );
  }
}

export default AppFormData;
