
import smMapcolumns from "./sm.mapcolumns";

export default {
  apiSchoolDataMoke() {
    let data = [
      {
        school_name: "school name A",
        admin_name: "admin name",
        mobile_number: "9878767656",
        email_id: "test@example.com",
        onboarding_date: "1 Jan 2023",
        city: "Location",
        state: "TS",
      },
      {
        school_name: "school name B",
        admin_name: "admin name",
        mobile_number: "9878767656",
        email_id: "test@example.com",
        onboarding_date: "10 Feb 2023",
        city: "Location",
        state: "AP",
      },
      {
        school_name: "school name C",
        admin_name: "admin name",
        mobile_number: "9878767656",
        email_id: "test@example.com",
        onboarding_date: "17 Jul 2023",
        city: "Location",
        state: "TN",
      },
      {
        school_name: "school name D",
        admin_name: "admin name",
        mobile_number: "9878767656",
        email_id: "test@example.com",
        onboarding_date: "18 Oct 2023",
        city: "Location",
        state: "PY",
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },
  // replaced with version 2 API

  // apiGetSchools(data) {
  //   let payload = {
  //     ...data,
  //   };

  //   return this.request(this.api().getSchool, payload)
  //     .exec()
  //     .log()
  //     .mapresults((response) => {
  //       return {
  //         data: response.data[0].records.map((record) =>
  //           smMapcolumns.mapFields("schools", record)
  //         ),
  //         total: response.data[0].pagination[0]?.totalRecords || 0,
  //       };
  //     })
  //     .get();
  // },
  apiAddSchool(data) {
    console.log({ apiAddSchool: data });
    let payload = {
      ...smMapcolumns.mapPayload("schools", data),
    };
    payload = Object.filter(payload, (v) => v);

    // const profile_id = JSON.parse(localStorage.getItem("userresponse")).data[0].records[0].profile_id;

    // payload = {admin_profile_id: profile_id, ...payload}
    // delete(payload.admin_name)

    return this.request(this.api().createSchool, payload).exec().log().get();
  },
  apiEditSchool(data, urldata = {}) {
    delete Object.assign(data, { ["admin_name"]: data["school_admin"] })[
      "school_admin"
    ];
    console.log({ apiEditSchool: data, urldata });
    urldata = { ...mixins.params(), ...urldata };
    // let payload =
    // {
    //     ...smMapcolumns.mapPayload('schools', data),
    // }
    // payload = Object.filter(payload, (v) => v);

    return this.request(this.api().updateSchoolById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteSchool(urldata = mixins.params()) {
    return this.request(this.api().deleteSchool)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiCreateEvent(data) {
    console.log("data", data);

    return this.request(this.api().createEvent, data).exec().log().get();
  },
  apiGetAllEvent(urldata = mixins.params()) {
    return this.request(this.api().getAllEvent)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetEventById(data, urldata = mixins.params) {
    urldata = { ...urldata, event_id: data };

    return this.request(this.api().getEventById, { event_id: data })

      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetSchoolEventById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getSchoolEventById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },

  apiAddGenericInfo(data) {
    console.log({ apiAddGenericInfo: data });
    let payload = {
      ...smMapcolumns.mapPayload("Generic Info", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addGenericInfo, payload).exec().log().get();
  },
  apiAddClassInfo(data) {
    let payload = {
      ...smMapcolumns.mapPayload("Class Info", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addClassInfo, payload).exec().log().get();
  },
  apiAddSubModules(data) {
    let payload = {
      ...smMapcolumns.mapPayload("Sub Modules", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addSubModules, payload).exec().log().get();
  },
  apiAddSchoolAdminInfo(data) {
    let payload = {
      ...smMapcolumns.mapPayload("School Admin Info", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addSchoolAdminInfo, payload)
      .exec()
      .log()
      .get();
  },
  apiAddSchoolCoordinateInfo(data) {
    let payload = {
      ...smMapcolumns.mapPayload("School Admin Info", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addSchoolCoordinator, payload)
      .exec()
      .log()
      .get();
  },
  apiCreateSchoolEvent(data) {
    return this.request(this.api().createSchoolEvent, data).exec().log().get();
  },
  apiAddDevice(data) {
    let payload = {
      ...smMapcolumns.mapPayload("Devices", data),
    };
    payload = Object.filter(payload, (v) => v);

    return this.request(this.api().addDevice, payload).exec().log().get();
  },

  apiEditGenericInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateGenericInfoById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditClassInfo(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateClassInfoById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditSubModules(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateSubModuleById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditInvoiceForm(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateInvoiceForm, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditTableById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().editTableById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditStudentById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().editStudentById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditSchoolEventById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateSchoolEventById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiEditDeviceById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateDeviceById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiOrderRazrorPayInvoice(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().orderRazrorPayInvoice, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetAllSchoolListSuperAdmin(data, urldata = {}) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getAllSchoolListSuperAdmin, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data[0].records,
        };
      })
      .get();
  },

  apiGetAllSchools(data, urldata = {}) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getAllSchoolList, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetAllSchoolList(data, urldata = {}) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getAllSchoolListForCoordinator, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetAllModules(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getAllModules)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetManageAccessData(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getManageAccess)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiUpdateManageAccessData(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateManageAccess, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetFinancialsList(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getFinancialsList, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetClassDetailsById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getClassDetailsById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return response;
      })
      .get();
  },
  apiGetClassById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getClassInfo)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiGetSubModules(data, urldata = {}) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getSubModules, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetSchoolAdminsById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getSchoolAdmins, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiGetSchoolCoordinatorsById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getSchoolCoordinators, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiGetProfileDetailsById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getProfileDetailsById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiGetAllSchoolEvent(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getAllSchoolEvent)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiGetDevice(data, urldata = {}) {
    let payload = {
      ...data,
    };
    return this.request(this.api().getDevices, payload)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiEditSchoolAdminById(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().updateSchoolAdminById, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiGetAdminSchoolById(urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().getAdminSchoolById)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        return {
          data: response.data,
        };
      })
      .get();
  },
  apiGetAllTableList(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getAllTableList, data)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((res) => {
        return res;
      })
      .get();
  },
  apiEditSchoolActivation(data, urldata = {}) {
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().activateSchoolOnboarding, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiDeleteSchoolAdmin(urldata = mixins.params()) {
    return this.request(this.api().deleteSchoolAdminById)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteSchoolCoordiator(urldata = mixins.params()) {
    return this.request(this.api().deleteCoordinatorById)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteClassConfig(urldata = mixins.params()) {
    return this.request(this.api().deleteClassInfoById)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
  apiDeleteTableById(urldata = mixins.params()) {
    return this.request(this.api().deleteTableById)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
};
